import type { CatalogProductDto, CatalogProductShortDto } from "shared/api"

export const productToItem = (product: CatalogProductShortDto | CatalogProductDto, skuId?: number): Gtag.Item => ({
  item_id: product.spuId.toString(),
  item_name: product.name,
  item_brand: product.brand,
  item_category: product.category.category1,
  item_category2: product.category.category2,
  item_category3: product.category.category3,
  item_variant: skuId?.toString(),
  price: !skuId ? product.price : "skus" in product ? product.skus.find((it) => it.skuId === skuId)?.price : undefined,
  currency: "RUB",
})
