"use client";

import styles from "./page-search.module.scss";
import { delay, KeyName, LocalStorage, typograf, useDisableBodyScroll, useKeyDown, UseModalProps } from "../../shared/lib";
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import * as Icons from "./assets";
import { catalogApi } from "../../shared/api";
import cx from "classnames";
import { useRouter } from "next/navigation";
import { analytics } from "entity/analytics";
interface IPageSearch extends UseModalProps {
  searchValue?: string;
}
export const PageSearch = (props: IPageSearch) => {
  const [searchValue, setSearchValue] = useState<string>(props.searchValue ?? "");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const onClose = () => props.close();
  const [lastSearch, setLastSearch] = useState<string[]>([]);
  const [moreLast, setMoreLast] = useState<boolean>(false);
  const router = useRouter();
  const [selectedItem, setSelectedItem] = useState<number>(-1);
  useKeyDown(KeyName.ESC, () => {
    analytics.send("search-page.searchInput.ESCButton");
    onClose();
  });
  useDisableBodyScroll(true);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("lastSearch")!);
    if (items) {
      setLastSearch(items);
    }
  }, []);
  const suggest = catalogApi.catalog.useCatalogCatalogSuggester(searchValue.trim(), 10);
  const [savedSuggest, setSavedSuggest] = useState<string[]>([]);
  useEffect(() => {
    if (suggest.data && suggest.data.suggestions.length !== 0) {
      setSavedSuggest(suggest.data.suggestions);
    }
  }, [suggest.data]);
  const popular = catalogApi.catalog.useCatalogCatalogSuggester("", 10);
  const [savedPopular, setSavedPopular] = useState<string[]>([]);
  useEffect(() => {
    if (popular.data) setSavedPopular(popular.data.suggestions);
  }, [popular.data]);
  const handleSearch = async (value: string, save?: boolean) => {
    if (value !== "") {
      if (save) {
        if (!lastSearch.includes(value)) {
          LocalStorage.setItem("lastSearch", JSON.stringify([value, ...lastSearch]));
        }
      }
      router.push("/search-page?sort=search-relevance&search=" + value);
      await delay(1000);
      props.close();
    }
  };
  const removeItemFromHistory = (item: string) => {
    analytics.send("search-page.lastSearch.deleteItem", {
      item: item
    });
    if (lastSearch.length === 1) {
      setLastSearch([]);
    } else {
      setLastSearch(prevState => prevState.filter(state => state !== item));
    }
  };
  const nextWords = useMemo(() => {
    return getAutocompleteSuggestions(searchValue.trim().toLowerCase(), savedSuggest || []);
  }, [savedSuggest, searchValue]);
  useKeyDown(KeyName.Enter, () => {
    analytics.send("search-page.searchInput.EnterButton", {
      searchValue: searchValue
    });
    if (selectedItem === -1) {
      handleSearch(searchValue, true);
    } else {
      handleSearch(savedSuggest[selectedItem], true);
    }
  });
  useEffect(() => {
    analytics.send("search-page.opened");
    return () => analytics.send("search-page.closed");
  }, []);
  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const regex = /^[a-zA-Zа-яА-Я0-9 ]+$/;
    if (value && !regex.test(value)) {
      return;
    }
    if (searchValue === "" && value.endsWith(" ")) {
      return;
    }
    setSavedSuggest([]);
    setSelectedItem(-1);
    setSearchValue(value);
  };
  const handleKeyDown = (e: {
    key: string;
  }) => {
    const key = e.key;
    if (key === "ArrowDown") {
      setSelectedItem(prevState => prevState === savedSuggest?.length - 1 ? prevState : prevState + 1);
    } else if (key === "ArrowUp") {
      setSelectedItem(prevState => prevState === -1 ? prevState : prevState - 1);
    }
  };
  return <div className={styles.wrapper} data-sentry-component="PageSearch" data-sentry-source-file="page-search.tsx">
      <div className={styles.overlay} onClick={onClose} />
      <div className={styles.content}>
        <div className={styles.searchInputContainer}>
          <button className={cx(styles.actionButton, styles.mobileButton)} onClick={onClose}>
            <Icons.backIcon data-sentry-element="unknown" data-sentry-source-file="page-search.tsx" />
          </button>
          <div className={styles.searchIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" data-sentry-element="svg" data-sentry-source-file="page-search.tsx">
              <path d="M19.25 19.25L13.75 13.75M15.5833 9.16667C15.5833 12.7105 12.7105 15.5833 9.16667 15.5833C5.62284 15.5833 2.75 12.7105 2.75 9.16667C2.75 5.62284 5.62284 2.75 9.16667 2.75C12.7105 2.75 15.5833 5.62284 15.5833 9.16667Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="page-search.tsx" />
            </svg>
          </div>
          <input ref={inputRef} className={styles.searchInput} value={searchValue} onChange={event => handleChangeInput(event)} onKeyDown={handleKeyDown} placeholder="Найти товары" />
          {searchValue !== "" && <div className={styles.actionsGroup}>
              <button className={styles.actionButton} onClick={() => {
            analytics.send("search-page.searchInput.clearInput");
            setSearchValue("");
          }}>
                <Icons.clearIcon />
              </button>
              <button className={cx(styles.actionButton, styles.mobileButton)} onClick={() => {
            analytics.send("search-page.searchInput.submit", {
              searchValue: searchValue
            });
            handleSearch(searchValue, true);
          }}>
                <Icons.searchIcon />
              </button>
              <button className={styles.searchButtonText} onClick={() => {
            analytics.send("search-page.searchInput.submit", {
              searchValue: searchValue
            });
            handleSearch(searchValue, true);
          }}>
                Найти
              </button>
            </div>}
        </div>
        <div className={styles.scrollListMobile}>
          {searchValue === "" ? <>
              {lastSearch.length !== 0 && <div className={styles.searchHistory}>
                  <h3 className={styles.searchHistoryTitle}>Вы искали</h3>
                  <div className={styles.searchHistoryList}>
                    {lastSearch.slice(0, 5).map((item: string, index: number) => <button key={index} onClick={() => {
                analytics.send("search-page.lastSearch.itemClicked", {
                  lastSearch: item
                });
                handleSearch(item, false);
              }}>
                        {item}
                        <button onClick={e => {
                  e.stopPropagation();
                  removeItemFromHistory(item);
                }}>
                          <Icons.closeButtonIcon />
                        </button>
                      </button>)}
                    {moreLast && lastSearch.slice(5).map((item: string, index: number) => <button key={index} onClick={() => {
                analytics.send("search-page.lastSearch.itemClicked", {
                  lastSearch: item
                });
                handleSearch(item, false);
              }}>
                          {item}
                          <button onClick={() => removeItemFromHistory(item)}>
                            <Icons.closeButtonIcon />
                          </button>
                        </button>)}
                  </div>
                  {!moreLast && lastSearch.length > 5 && <button onClick={() => {
              analytics.send("search-page.lastSearch.moreClicked");
              setMoreLast(true);
            }}>
                      Еще <Icons.chevronDownIcon />
                    </button>}
                </div>}
              <div className={styles.oftenSearched}>
                <h3 className={styles.oftenSearchedTitle}>Часто ищут</h3>
                {savedPopular?.map((item, index) => <button className={styles.searchItem} key={index} onClick={() => {
              analytics.send("search-page.popular.clicked", {
                searchValue: searchValue,
                popularItem: item
              });
              handleSearch(item, true);
            }}>
                    <Icons.searchItemIcon />
                    <h2>{item}</h2>
                  </button>)}
              </div>
            </> : <>
              <div className={styles.plits}>
                {nextWords.map(it => <button key={it} onClick={() => {
              analytics.send("search-page.plits.clicked", {
                searchValue: searchValue,
                plitsItem: it
              });
              setSearchValue(searchValue.trim() + " " + it);
            }}>
                    {it}
                  </button>)}
              </div>
              <div className={styles.suggestionsList}>
                {(savedSuggest.length === 0 ? [searchValue] : savedSuggest)?.map((item, index) => <button key={index} onClick={() => {
              analytics.send("search-page.clickedSuggest.fromMobile", {
                searchValue: searchValue,
                suggestValue: item
              });
              handleSearch(item, true);
            }} className={styles.searchItem}>
                    <Icons.searchItemIcon />
                    <h2>
                      <span>{searchValue}</span>
                      {item.split(searchValue.toLowerCase())[1]}
                    </h2>
                  </button>)}
              </div>
            </>}
        </div>
        <div className={styles.searchListDesktop}>
          {searchValue === "" ? <>
              {lastSearch.length !== 0 && <div className={styles.searchHistoryDesktop}>
                  <h2>Вы искали</h2>
                  {lastSearch.slice(0, 4).map((item: string, index: number) => <button key={index} onClick={() => {
              analytics.send("search-page.lastSearch.itemClicked", {
                lastSearch: item
              });
              handleSearch(item, false);
            }}>
                      <div className={styles.main}>
                        <Icons.searchHistoryIcon />
                        {item}
                      </div>
                      <button onClick={e => {
                e.stopPropagation();
                removeItemFromHistory(item);
              }}>
                        <Icons.closeButtonIcon />
                      </button>
                    </button>)}
                </div>}
              <div className={styles.searchHistoryDesktop}>
                <h2>Часто ищут</h2>
                {savedPopular.slice(0, 8).map((item: string, index: number) => <button key={index} onClick={() => {
              analytics.send("search-page.popular.clicked", {
                searchValue: searchValue,
                popularItem: item
              });
              handleSearch(item, true);
            }}>
                    <div className={styles.main}>
                      <Icons.searchItemDesktopIcon />
                      {item}
                    </div>
                  </button>)}
              </div>
            </> : (savedSuggest.length === 0 ? [searchValue] : savedSuggest)?.map((item, index) => <button onClick={() => {
          analytics.send("search-page.clickedSuggest.fromPC", {
            searchValue: searchValue,
            suggestValue: item
          });
          handleSearch(item, true);
        }} data-selected={selectedItem === index} key={index}>
                <div>
                  <span>{searchValue}</span>
                  {item.split(searchValue.toLowerCase())[item.split(searchValue.toLowerCase()).length - 1]}
                </div>
              </button>)}
        </div>
      </div>
    </div>;
};
function getAutocompleteSuggestions(value: string, autocomplete: string[]): string[] {
  value = value.trim();
  // Разделяем входное значение на слова
  const words = value.split(" ");

  // Фильтруем автодополнения, которые начинаются с входного значения
  const filteredAutocomplete = autocomplete.filter(item => item.startsWith(value));

  // Если нет подходящих автодополнений, возвращаем пустой массив
  if (filteredAutocomplete.length === 0) {
    return [];
  }

  // Если входное значение совпадает с одним из автодополнений, ищем следующее слово
  if (filteredAutocomplete.includes(value)) {
    const nextWords = new Set<string>();

    // Ищем следующее слово после совпадения
    for (const item of filteredAutocomplete) {
      // @ts-ignore
      const itemWords = typograf(item).split(" ");
      if (itemWords.length > words.length) {
        nextWords.add(itemWords[words.length]);
      }
    }
    return Array.from(nextWords);
  }

  // Если входное значение является частью автодополнения, возвращаем следующее слово
  const firstMatch = filteredAutocomplete[0];
  const matchWords = firstMatch.split(" ");
  if (matchWords.length > words.length) {
    return [matchWords[words.length - 1]];
  }
  return [];
}