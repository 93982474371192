import { isProduction, sendGtmEvent, yandexMetrika } from "shared/lib"
import { productToItem } from "./converters/product-to-item"
import * as amplitude from "@amplitude/analytics-browser"
import posthog from "posthog-js"

export * from "./events/image-load-error"
export * from "./events/select-item"
export * from "./events/view-item-list"

export const login = (params: { method: "phone" | "telegram" | "onetime" }) => {
  sendGtmEvent({ event: "login", method: params.method })
  sendGtmEvent({ event: "generate_lead" })

  if (params.method === "phone") {
    yandexMetrika.reachGoal("login-phone")
  }
  if (params.method === "telegram") {
    yandexMetrika.reachGoal("login-telegram")
  }
  if (params.method === "onetime") {
    yandexMetrika.reachGoal("login-onetime")
  }
}

export const startLogin = (params: { method: "phone" | "telegram" }) => {
  sendGtmEvent({ event: "start_login", method: params.method })

  if (params.method === "phone") {
    yandexMetrika.reachGoal("start-login-phone")
  } else if (params.method === "telegram") {
    yandexMetrika.reachGoal("start-login-telegram")
  }
}

export const generateLead = () => {
  sendGtmEvent("generate_lead")
}

export const clickPay = () => {
  sendGtmEvent({ event: "click_pay" })
}

export const send = (event: string, properties: Object = {}) => {
  if (!isProduction) console.log("analytics event", { event, properties })
  if (process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY) {
    amplitude.track(event, properties)
  }
  if (process.env.NEXT_PUBLIC_POSTHOG_KEY) {
    posthog.capture(event, properties)
  }
  sendGtmEvent({ ...properties, event })
}

export const addToWishlist = async (params: { currency?: "RUB"; value?: number; items: Gtag.Item[] }) => {
  sendGtmEvent({ event: "add_to_wishlist", ecommerce: params })
  yandexMetrika.reachGoal("like")
}

export const viewItem = async (params: { currency?: "RUB"; value?: number; items: Gtag.Item[] }) => {
  sendGtmEvent({ event: "view_item", ecommerce: params })
}

export const beginCheckout = async (params: {
  currency?: "RUB"
  value?: number
  coupon?: string
  items: Gtag.Item[]
}) => {
  sendGtmEvent({ event: "begin_checkout", ecommerce: params })
}

export const addShippingInfo = async (params: {
  currency?: "RUB"
  value?: number
  coupon?: string
  shipping_tier?: string
  items: Gtag.Item[]
}) => {
  sendGtmEvent({ event: "add_shipping_info", ecommerce: params })
}

export const addPaymentInfo = async (params: {
  currency?: "RUB"
  value?: number
  coupon?: string
  payment_type?: string
  items: Gtag.Item[]
}) => {
  sendGtmEvent({ event: "add_payment_info", ecommerce: params })
}

export const convert = {
  productToItem,
}

export const viewedTopProductsTgCards = async () => {
  sendGtmEvent({ event: "viewed_top_products_tg_cards" })
}

export const clickedTopProductsTgCards = async () => {
  sendGtmEvent({ event: "clicked_top_products_tg_cards" })
}

export const viewedTgSupportCard = async () => {
  sendGtmEvent({ event: "viewed_tg_support_card" })
}

export const clickedTgSupportCard = async () => {
  sendGtmEvent({ event: "clicked_tg_support_card" })
}
